<template>
  <div>
    <topbar category="login"></topbar>
    <!-- logo标题 -->
    <div class="loginTitle">
      <div class="logo"><img src="@/assets/icon/logo@2x.png" alt="" /></div>
      <div class="title">
        <p>{{ zhangHaoLogin }}</p>
        <!-- <p>{{ welcomeKFC }}</p> -->
      </div>
    </div>
    <div class="tab">
      <span @click="selectLogin(1)" :class="{ active: showBtn }">{{
        phoneTab
      }}</span>
      <span @click="selectLogin(2)" :class="{ active: !showBtn }">{{
        email
      }}</span>
    </div>

    <div class="loginForm">
      <div class="phoneLogin">
        <div class="phone">
          <!-- 手机号 电话 登陆 -->
          <div v-show="showBtn" class="phoneSelect">
            <span @click="selectLogPhoneArea">+{{ phoneShowLogCode }}</span>
            <span></span>
            <div class="phoneBox" :class="{ active: phoneShowLog }">
              <p @click="selectLogCode(852)">+852</p>
              <p @click="selectLogCode(823)">+823</p>
            </div>
          </div>

          <input
            @input="changeBtn"
            type="text"
            v-model.trim="phone"
            :placeholder="showBtn ? login_shouJi : email"
          />
        </div>
        <div class="password">
          <input
            :type="inputType"
            @input="changeBtn"
            v-model.trim="password"
            :placeholder="passwordText"
          />
          <span
            @click="showPassword"
            :class="{ active: inputType == 'password' }"
          ></span>
        </div>
      </div>
    </div>
    <!-- 保持登陆 -->
    <div class="keepLogin">
      <p @click="rememberMe">
        <span :class="{ active: remember }"></span>
        {{ memberMe }}
      </p>
      <p class="setPassword" @click="setPassword">{{ forgetPassword }}</p>
    </div>
    <!-- 登陆按钮 -->
    <div class="loginBtn" :class="{ active: loginBtn }" @click="login">
      {{ dengRu }}
    </div>
    <p class="register" @click="register">
      {{ zhuCeZhangHu }} <br />
      <span>{{ zhuCeZhangHuText }}</span>
    </p>
    <div class="botFrame" :class="{ active: showFrame }">
      <div class="sendSMS">
        <p class="sendTitle"><i @click="setPassword"></i>{{ newPas }}</p>
        <div class="phone">
          <!-- 手机号 电话 登陆 -->
          <div v-show="showBtn" class="phoneSelect">
            <span @click="selectPhoneArea">+{{ phoneCode }}</span>
            <span></span>
            <div class="phoneBox" :class="{ active: phoneShow }">
              <p @click="selectCode(852)">+852</p>
              <p @click="selectCode(823)">+823</p>
            </div>
          </div>
          <input
            @input="changeForgetBtn"
            v-model.trim="forgetPhone"
            type="number"
            :placeholder="login_shouJi"
          />
        </div>
        <p class="sendAlert">{{ newPasSMS }}</p>
        <div
          class="loginBtn"
          :class="{ active: forgetActive }"
          @click="updatePas"
        >
          {{ confirm }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Topbar from "../../components/Topbar.vue";
import language from "../../assets/js/language.js";
import { postPaas, postDC } from "../../assets/utils/request";

export default {
  name: "Login",
  data() {
    return {
      pageParam: null,
      showBtn: true,
      inputType: "password",
      phone: "",
      // 12355851
      password: "",
      // 111111
      loginBtn: false,
      showFrame: false,
      sendActive: true,
      forgetActive: false,
      remember: false, //是否记住密码
      forgetPhone: null, //忘记密码手机号
      phoneShow: false,
      phoneShowLog: false,
      phoneShowLogCode: "852",
      phoneCode: "852",
      // 静态文字
      zhangHaoLogin: "",
      welcomeKFC: "",
      phoneText: "",
      login_shouJi: "",
      phoneTab: "",
      email: "",
      passwordText: "",
      memberMe: "",
      forgetPassword: "",
      dengRu: "",
      zhuCeZhangHu: "",
      zhuCeZhangHuText: "",
      newPas: "",
      newPasSMS: "",
      confirm: "",
      New_QSRSJH: "",
      DLSB:"",
      HQXXSB:""
    };
  },
  methods: {
    selectCode(code) {
      this.phoneCode = code;
      this.selectPhoneArea();
    },
    selectLogCode(type) {
      this.phoneShowLogCode = type;
      this.selectLogPhoneArea();
    },
    selectLogPhoneArea() {
      // this.phoneShowLog = !this.phoneShowLog;
    },
    selectPhoneArea() {
      // this.phoneShow = !this.phoneShow;
    },
    selectLogin(type) {
      this.phone = "";
      this.password = "";
      this.inputType = "password";
      this.loginBtn = false;
      this.showBtn = type == 1 ? true : false;
      var languageType;
      if (this.$store.state.language == 1) {
        languageType = "chinese";
      } else {
        languageType = "english";
      }
      if (this.showBtn) {
        this.phoneText = language.phone[languageType];
      } else {
        this.phoneText = language.email[languageType];
      }
      window.dataLayer.push({
        event: type == 1 ? "login_mobile" : "login_email",
      });
    },
    showPassword() {
      this.inputType = this.inputType == "password" ? "text" : "password";
    },
    changeBtn() {
      if (this.phone.length > 8) {
        this.phone = this.phone.slice(0, 8);
      }
      this.loginBtn = this.password.length >= 6 && this.phone ? true : false;
    },
    // 忘记密码按钮
    changeForgetBtn() {
      if (this.forgetPhone.length > 8) {
        this.forgetPhone = this.forgetPhone.slice(0, 8);
      }
      this.forgetActive = this.forgetPhone.length < 8 ? false : true;
    },
    setPassword() {
      this.showFrame = !this.showFrame;
      if (this.showFrame) {
        window.dataLayer.push({
          event: "forget_pw",
        });
      }
    },
    async updatePas() {
      this.sendActive = false;
      if (!this.forgetActive) return;
      var params = {
        actionName: "candao.member.passwordForget",
        content: {
          areaCode: this.phoneCode,
          loginKey: this.forgetPhone,
        },
      };
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.sendActive = true;
        this.showFrame = !this.showFrame;
        window.dataLayer.push({
          event: "confirm_forget_pw",
        });
      } else {
        this.$toast(this.HQXXSB);
      }
    },
    register() {
      this.$router.push("/register");
      window.dataLayer.push({
        event: "register",
      });
    },
    // 记住我
    rememberMe() {
      this.remember = !this.remember;
      window.dataLayer.push({
        event: "stay_login",
      });
    },
    //登陆
    async login() {
      if (!this.loginBtn) return;
      var params = {
        actionName: "candao.member.login",
        content: {
          loginKey: this.phone,
          password: this.password,
          areaCode: this.phoneShowLogCode,
          deviceId: "iPhone",
        },
      };
      // 登陆1
      let userInfo = await postPaas("UserUnify", params);
      if (userInfo.status != 1) {
        return this.$toast(this.HQXXSB);
      }
      let paramsOne = {
        actionName: "candao.user.getUserByExtUserId",
        key: "40a443f1ced7f597",
        content: {
          extUserId: userInfo.data.userId,
          phone: this.phone,
        },
      };
      // 获取secretKey
      let canDaoUser = await postDC("Action", paramsOne);
      if (canDaoUser.status != 1) {
        return this.$toast(this.DLSB);
      }
      var paramsTwo = {
        actionName: "candao.member.bindAccessCode",
        key: "40a443f1ced7f597",
        secretKey: canDaoUser.data.secretKey,
        content: {
          accessCode: userInfo.data.accessCode,
          deviceId: "iPhone",
        },
      };

      // 绑定accessCode
      let bindCode = await postPaas("UserUnify", paramsTwo);

      if (bindCode.status == 1) {
        window.dataLayer.push({
          event: "login",
        });
        this.$store.commit("setUserInfo", userInfo.data);
        sessionStorage.setItem("store", JSON.stringify(this.$store.state));
        var page = this.$route.query.page;
        if (page == 1) {
          this.$router.back(-1);
          return;
        }
        this.$router.push("/");
      } else {
        this.$toast(this.HQXXSB);
      }
    },
  },
  mounted() {},
  created() {
    var languageType;
    if (this.$store.state.language == 1) {
      languageType = "chinese";
    } else {
      languageType = "english";
    }
    this.zhangHaoLogin = language.zhangHaoLogin[languageType];
    this.welcomeKFC = language.welcomeKFC[languageType];
    this.phoneText = language.phone[languageType];
    this.login_shouJi = language.login_shouJi[languageType];
    this.email = language.email[languageType];
    this.passwordText = language.password[languageType];
    this.memberMe = language.memberMe[languageType];
    this.forgetPassword = language.forgetPassword[languageType];
    this.dengRu = language.dengRu[languageType];
    this.zhuCeZhangHu = language.zhuCeZhangHu[languageType];
    this.zhuCeZhangHuText = language.zhuCeZhangHuText[languageType];
    this.newPas = language.newPas[languageType];
    this.newPasSMS = language.newPasSMS[languageType];
    this.confirm = language.confirm[languageType];
    this.New_QSRSJH = language.New_QSRSJH[languageType];
    this.phoneTab = language.phoneTab[languageType];
    this.HQXXSB=language.HQXXSB[languageType]
    this.DLSB=this.$store.state.language==1?"無法登入，請稍候再試":"Login failed, please try again later"
  },
  components: {
    Topbar,
  },
};
</script>
<style scoped>
.loginTitle {
  width: 100%;
  padding: 0.16rem 0.16rem 0;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.logo {
  width: 0.5rem;
  height: 0.5rem;
  position: relative;
}
.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.title {
  height: 100%;
  font-size: 0.2rem;
  font-family: PingFang SC;
  font-weight: 600;
  color: #03060d;
  margin-left: 0.08rem;
}
.title p:nth-child(2) {
  font-size: 0.11rem;
  color: #808080;
}
.tab {
  margin: 0.48rem 0.16rem 0.3rem;
  height: 0.36rem;
  font-size: 0.14rem;
  font-weight: 500;
  color: #e4022b;
  border-radius: 0.08rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e4022b;
}
.tab span {
  width: 50%;
  height: 100%;
  text-align: center;
  line-height: 0.36rem;
  border-radius: 0.08rem;
}
.tab span.active {
  background-color: #e4022b;
  color: #fff;
  border-left: 1px solid #e4022b;
  border-right: 1px solid #e4022b;
}
.phoneLogin {
  margin: 0 0.16rem;
}
.phone,
.password,
.callPhone {
  width: 100%;
  height: 0.61rem;
  border-bottom: 1px solid #cacbcc;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.phoneSelect {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.16rem;
  font-family: Open Sans;
  font-weight: 400;
  color: #051b24;
  position: relative;
}
.phoneSelect span:nth-child(2) {
  width: 0.12rem;
  height: 0.12rem;
  /* background: url("@../../../../assets/icon/login_icon_arrow@2x.png") no-repeat center; */
  background-size: cover;
  margin: 0 0.116rem 0 0.04rem;
}
.phone input,
.password input {
  width: 2.5rem;
}
input {
  border: none;
  outline: none;
  height: 0.22rem;
  text-align: left;
  font-size: 0.16rem;
  font-family: Open Sans;
  font-weight: 400;
  color: #051b24;
}
.password {
  justify-content: space-between;
}
.password input {
  width: 3rem;
}
.password span {
  width: 0.28rem;
  height: 0.28rem;
  background: url("@../../../../assets/icon/login_icon_hide@2x.png") no-repeat
    center;
  background-size: cover;
}
.password span.active {
  background: url("@../../../../assets/icon/showpas.png") no-repeat center;
  background-size: cover;
}
.callPhone input {
  width: 100%;
}
.keepLogin {
  margin: 0.16rem 0.16rem 0.48rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.keepLogin p:first-child {
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.22rem;
  color: #131314;
  display: flex;
  justify-content: center;
  align-items: center;
}
.keepLogin p:first-child span {
  width: 0.16rem;
  height: 0.16rem;
  background: url("@../../../../assets/icon/cycle.png") no-repeat center;
  background-size: cover;
  margin-right: 4px;
}
.keepLogin p:first-child span.active {
  background: url("@../../../../assets/icon/icon@2x.png") no-repeat center;
  background-size: cover;
}
.keepLogin p:last-child {
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.22rem;
  color: #808080;
  /* border-bottom: 1px solid #e4022b; */
}
.loginBtn {
  margin: 0.48rem 0.16rem 0.16rem;
  height: 0.44rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.44rem;
  color: #808080;
  background-color: #ccc;
  border-radius: 0.08rem;
}
.loginBtn.active {
  background-color: #e4022b;
  color: #fff;
  font-family: Roboto;
}
.register {
  width: 100%;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #b1b1b3;
}
.register span {
  font-size: 0.12rem !important;
  color: #218bce;
}
.botFrame {
  width: 100%;
  height: 100vh;
  position: fixed;
  bottom: -100vh;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s linear;
}
.botFrame.active {
  bottom: 0;
}
.sendSMS {
  background-color: #fff;
  border-radius: 0.1rem 0.1rem 0 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 0.1rem;
}
.sendSMS .phone {
  margin: 0 0.16rem;
  box-sizing: border-box;
}
.sendAlert {
  height: 17px;
  font-size: 12px;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 17px;
  color: #808080;
  text-align: left;
  padding: 0.08rem 0.16rem 0.16rem;
}
.sendSMS .loginBtn {
  margin-top: 0;
}
.sendTitle {
  width: 100%;
  height: 28px;
  font-size: 20px;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 28px;
  color: #1f2123;
  margin: 0.24rem 0 0.12rem;
  text-align: center;
  position: relative;
}
.sendTitle i {
  width: 0.24rem;
  height: 0.24rem;
  background: url("../../assets/icon/login_close.png") no-repeat center;
  background-size: cover;
  position: absolute;
  left: 0.16rem;
  top: 50%;
  transform: translateY(-50%);
}
.phoneBox {
  height: 0;
  position: absolute;
  top: 0.2rem;
  left: 0;
  /* z-index: ; */
  background-color: #fff;
  overflow: hidden;
  transition: all 0.2s linear;
  font-size: 0.16rem;
}
.phoneBox.active {
  height: 60px;
}
</style>
